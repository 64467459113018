import React, { useRef } from "react"
import Lottie from "lottie-react"
import logo from "../../../static/assets/objective.json"

export const LogoAnimation = () => {
  const animationLogo = JSON.parse(JSON.stringify(logo))
  const lottieRef = useRef()
  return (
    <div style={{ position: "relative", marginBottom: "-3em" }}>
      <Lottie
        id="animation"
        lottieRef={lottieRef}
        autoplay={true}
        loop={true}
        animationData={animationLogo}
      />
    </div>
  )
}
