import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Logo from "../components/UX/Logo"
import {
  RevealTitle,
  RevealChild,
  ImageOnScroll,
} from "../components/UX/VisibilitySensor"
import Section from "../components/UX/Section"
import ContactSection from "../components/UX/contact-section"
import { LogoAnimation } from "../components/UX/lottie"
import { TeamMember } from "../components/UX/team-panel"

const IndexPage = ({ data }) => {
  const {
    statement,
    objective,
    who,
    founders,
    advisors,
    strategy,
    facilities,
  } = data.file.childMarkdownRemark.frontmatter

  return (
    <Layout>
      <Seo title="Home" />
      <Section>
        <div className="container grid-by-4">
          <Logo />
          <div className="grid-right">
            <RevealTitle barStyle="right" directionUp={false}>
              <p>{statement}</p>
            </RevealTitle>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container grid-2-columns">
          <RevealTitle directionUp={true} barStyle="left">
            <h1>What's our objective?</h1>
          </RevealTitle>
          <p
            style={{ gridRowStart: "2", marginTop: "24px" }}
            dangerouslySetInnerHTML={{ __html: objective }}
          />
          <div style={{ gridRowStart: "2" }}>
            <RevealChild>
              <LogoAnimation />
            </RevealChild>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container">
          <div className="grid-by-4">
            <div>
              <h1>Who's who</h1>
              <RevealTitle directionUp={false} barStyle="left">
                <p dangerouslySetInnerHTML={{ __html: who }} />
              </RevealTitle>
            </div>
          </div>
          <div>
            <div style={{ display: "grid" }}>
              <div className="grid-right">
                <RevealTitle barStyle="right" directionUp={false}>
                  <h2 style={{ textAlign: "left" }}>Founders</h2>
                </RevealTitle>
              </div>
            </div>
            <div className="flex-row">
              <TeamMember members={founders} />
            </div>
          </div>
          <div>
            <div className="grid-2-columns">
              <div>
                <RevealTitle barStyle="left" directionUp={false}>
                  <h2 style={{ textAlign: "right" }}>Portfolio Advisors</h2>
                </RevealTitle>
              </div>
            </div>
            <div className="flex-row">
              <TeamMember members={advisors} />
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container">
          <div className="grid-2-columns">
            <RevealTitle directionUp={true} barStyle="left">
              <h1>Strategies</h1>
            </RevealTitle>
            <div style={{ gridRowStart: "2" }}>
              <p dangerouslySetInnerHTML={{ __html: strategy.statement }} />
              <div
                className="flex-col"
                style={{
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  height: "100%",
                }}
              >
                <Link
                  className="nav-item"
                  to="/strategies/#total-return-balanced"
                >
                  <h1>Total Return</h1>
                </Link>
                <Link className="nav-item" to="/strategies/#global-macro">
                  <h1>Global Macro</h1>
                </Link>
                <Link
                  className="nav-item"
                  to="/strategies/#smart-alpha---us-growth"
                >
                  <h1>Smart Alpha</h1>
                </Link>
              </div>
            </div>
            <div className="grid-right">
              <ImageOnScroll image={strategy.image} excerpt={strategy.quote} />
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container">
          <div className="grid-2-columns">
            <RevealTitle directionUp={true} barStyle="left">
              <h1>Facilities & Arrangements</h1>
            </RevealTitle>
          </div>
          <div>
            <div className="grid-2-columns">
              <div className="grid-right">
                <RevealTitle barStyle="right" directionUp={false}>
                  <h2 style={{ textAlign: "left" }}>Account facilities</h2>
                </RevealTitle>
              </div>
            </div>
            <div className="flex-row">
              <p
                style={{ gridRowStart: "2" }}
                dangerouslySetInnerHTML={{ __html: facilities.account }}
              />
            </div>
          </div>
          <div>
            <div className="grid-2-columns">
              <div>
                <RevealTitle barStyle="left" directionUp={false}>
                  <h2 style={{ textAlign: "right" }}>Custody arrangements</h2>
                </RevealTitle>
              </div>
            </div>
            <div className="flex-row">
              <p
                style={{ gridRowStart: "2" }}
                dangerouslySetInnerHTML={{ __html: facilities.custody }}
              />
            </div>
          </div>
          <div>
            <div className="grid-2-columns">
              <div className="grid-right">
                <RevealTitle barStyle="right" directionUp={false}>
                  <h2 style={{ textAlign: "left" }}>Reporting</h2>
                </RevealTitle>
              </div>
            </div>
            <div className="flex-row">
              <p
                style={{ gridRowStart: "2" }}
                dangerouslySetInnerHTML={{ __html: facilities.reporting }}
              />
            </div>
          </div>
          <div>
            <div className="grid-2-columns">
              <div>
                <RevealTitle barStyle="left" directionUp={false}>
                  <h2 style={{ textAlign: "right" }}>Fees</h2>
                </RevealTitle>
              </div>
            </div>
            <div className="flex-row">
              <p
                style={{ gridRowStart: "2" }}
                dangerouslySetInnerHTML={{ __html: facilities.fees }}
              />
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <ContactSection data={data} />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    file(name: { eq: "home" }) {
      id
      childMarkdownRemark {
        frontmatter {
          statement
          objective
          who
          founders {
            member
          }
          advisors {
            member
          }
          facilities {
            account
            custody
            reporting
            fees
          }
          strategy {
            statement
            quote
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [PNG, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    allFile(filter: { name: { eq: "contact" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              subtitle
              offices {
                address
                email
                location
                name
                telephone
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [PNG, WEBP, AVIF]
                      layout: CONSTRAINED
                      width: 300
                      height: 300
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
